<template>
	<BaseLoading v-if="isLoading" is-full-page />
	<div v-else>
		<FormBrandInstallmentPlan
			:is-submitting="installment.edit.isUpdating"
			:group-id="groupId"
			:method-id="methodId"
			:brand-id="brandId"
			:gateway="paymentMethodDetails.gateway"
			:plans="installment.edit.data.installmentPlans"
			:name="installment.edit.data.name"
			:config-by="installment.edit.data.configBy"
			:config-data="installment.edit.data.configData"
			is-edit
			@onSubmit="handleSubmit"
		/>
	</div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import FormBrandInstallmentPlan from '@/components/FormBrandInstallmentPlan.vue';
import bayPaymentSetting from '../assets/mock/bayPaymentSetting.json';

export default {
	name: 'BrandInstallmentPlanEdit',

	components: {
		FormBrandInstallmentPlan,
	},
	data() {
		return {
			isLoading: false,
			mockPlans: bayPaymentSetting,
		};
	},
	computed: {
		...mapState('payments', {
			installment: 'installmentBrand',
		}),

		...mapGetters({
			paymentMethodDetails: 'payments/paymentMethodDetails',
		}),

		methodId() {
			return this.$route.params.methodId;
		},
		groupId() {
			return this.$route.params.id;
		},
		brandId() {
			return this.$route.params.brandId;
		},
	},
	async created() {
		this.isLoading = true;
		await Promise.all([
			this.getPaymentMethod(this.methodId),
			this.getInstallmentPlanGroup({
				methodId: this.methodId,
				brandId: this.brandId,
			}),
		]);
		this.isLoading = false;

		// Set breadcrumb
		this.setBreadcrumbs([
			{
				title: 'Payment settings',
				route: { name: 'PaymentList' },
			},
			{
				title: `Installment credit card / ${this.paymentMethodDetails.name}`,
				route: {
					name: 'PaymentMethodSetting',
					params: { id: this.$route.params.id, methodId: this.$route.params.methodId },
				},
			},
		]);
	},
	methods: {
		...mapActions({
			getInstallmentPlanGroup: 'payments/getInstallmentPlanGroup',
			updateInstallmentPlanGroup: 'payments/updateInstallmentPlanGroup',
			getPaymentMethod: 'payments/getPaymentMethod',
			setBreadcrumbs: 'breadcrumb/setBreadcrumbs',
		}),

		async handleSubmit(params) {
			await this.updateInstallmentPlanGroup({
				methodId: this.methodId,
				brandId: this.brandId,
				params,
			});
		},
	},
};
</script>
